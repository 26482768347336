<template>
  <div>
    <OptionSetDialogComponent
    :OptionSetDialog="OptionSetDialog" :EditOptionSet="EditOptionSet"
    @NoPropsemit="NoPropsemit" @Onepropemit="Onepropemit"
    @SaveOptionSets="SaveOptionSets"
    @Threepropemit="Threepropemit"
    />
    <!-- <CabinetORSubcolConfig :System="System" :SystemEntities="SystemEntities" :NewTableOption="NewTableOption"
                        :SampleTableOption="SampleTableOption" @Onepropemit="Onepropemit" @NoPropsemit="NoPropsemit" :CurrentEntity="SelectedEntity"
                        /> -->
    <v-dialog max-width="600" v-model="NewFieldDialog">                               
        <v-card>
            <v-card-title class="overline">
                {{NewField.Type}}
            </v-card-title>
            <v-card-text v-html="NewField.Description">
            </v-card-text>
            <!-- <v-card-text> -->
                <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
                 :CurrentEntity="CurrentEntity" @RefreshField="RefreshField" v-if="!RefreshingNewField"
                :SystemEntities="SystemEntities" :field="NewField" :NewField="AddingNewField" :System="System"
                />
            <v-card-text>
                <SingleTabFieldsSection
                    @UpdateEditableField="UpdateEditableField"
                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj"
                :sectionindex="''" :tabindex="''" :SampleOnly="true" :System="System"
                :PrimaryFieldName="''" :SingleFormEditing="false" :CurrentEntity="CurrentEntity"
                :Record="SampleRecord" :AdditionalSaveMethod="AdditionalSaveMethod"
                :section="''" :tab="''" :Fields="[NewField]" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelNewFieldDialog()">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="AddField(NewField)">
                   
                    {{AddingNewField? 'Add Field' : 'Update Field'}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
     <v-dialog max-width="500" v-model="NewTableOptionDialog" persistent>        
        <v-card>
            <v-card-title class="overline">
                {{NewTableOption.OptionType}}
            </v-card-title>
            <v-card-text v-html="NewTableOption.Description">
            </v-card-text>
            <v-card-text>
                
                <v-list dense>
                    <v-list-item>
                        <v-text-field :filled="NewTableOption.id" :readonly="NewTableOption.id" v-model="NewTableOption.Name" label="Name" />
                    </v-list-item>
                    <v-list-item>
                        <v-text-field v-model="NewTableOption.DisplayName" label="DisplayName" />
                    </v-list-item>
                </v-list>
                <v-list dense v-if="NewTableOption.OptionType === 'Document Register' || NewTableOption.OptionType === 'Gallery'">
                    <v-list-item>
                    <v-select v-model="NewTableOption.CreatePermission" label="Create" :items="['Inherited','Custom Role','Admin']"/>
                    </v-list-item>
                    <v-list-item>
                    <v-select v-model="NewTableOption.GetPermission" label="Get (Open)" :items="['Inherited','Custom Role','Admin']"/>
                    </v-list-item>
                    <v-list-item>
                    <v-select v-model="NewTableOption.ListPermission" label="See the list" :items="['Inherited','Custom Role','Admin']"/>
                    </v-list-item>
                    <v-list-item>
                    <v-select v-model="NewTableOption.UpdatePermission" label="Update (e.g. change Class, or Table)" :items="['Inherited','Custom Role','Admin']"/>
                    </v-list-item>
                    <v-list-item>
                    <v-select v-model="NewTableOption.DeletePermission" label="Delete" :items="['Inherited','Custom Role','Admin']"/>
                    </v-list-item>
                    <v-list-item dense v-if="NewTableOption.OptionType === 'Document Register'">
                        <v-btn @click="ActivateOptionSetDialog(NewTableOption)">Options</v-btn>
                    </v-list-item>
                </v-list>
                <v-list  v-if="NewTableOption.OptionType === 'SubCollection'">
                    <v-list-item>
                            <v-chip-group
                            active-class="primary--text"
                            column
                        >
                        <v-chip
                            @click="ActivateNewFieldDialog(field,true)"
                            small  v-for="field in FieldTypesSearched"
                            :key="field.itemObjKey"
                            >
                            {{field.Type}}
                            </v-chip>                           
                        </v-chip-group>
                    </v-list-item>
                    <v-list-item>
                                <v-dialog v-model="importdialog" max-width="500px">
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="green" dark class="mb-2" v-on="on">Import</v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                        <span class="headline">Import Dialog</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                <v-text-field v-model="ImportFileSessionName" label="Name"></v-text-field>
                                                <input v-if="!ImportFile"
                                                        type="file"
                                                        @change="onImportfileSelected($event)"
                                                        ref="ImportFileinputter"
                                                        id="fileUpload"
                                                        >
                                                <v-btn @click="DownloadFieldsImportTemplate()">Download Template</v-btn>
                                            </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeImport()">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="saveImport()">Save</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    </v-dialog>
                            </v-list-item>
                        <v-list-item>
                            <!-- {{NewTableOption.Headers}} -->
                        <!-- <v-autocomplete return-object multiple chips v-model="NewTableOption.Headers" :items="NewTableOption.Headers" label="Headers" item-text="Name" readonly>
                            <template v-slot:selection="data">
                                 -->
                                  <v-chip-group
                            active-class="primary--text"
                            column
                        >
                                <v-chip
                                    @click="ActivateNewFieldDialog(field,true)"
                                    small  v-for="(field) in NewTableOption.Headers"
                                    :key="field.itemObjKey"
                                    >
                                    {{field.Name}}
                                    </v-chip>     
                                  </v-chip-group>                      
<!--                                 
                            </template>
                        </v-autocomplete> -->
                    </v-list-item>
                    <v-list-item>
                        <v-select @change="ResetTableOptionDialog()" v-model="NewTableOption.Type" label="Type" :items="['Lookup','Standard']"/>
                    </v-list-item>
                    <!-- NewTableOption.Type {{NewTableOption.Type}} -->
                    <v-list-item v-if="NewTableOption.Type === 'Lookup'">
                            <!-- @change="UpdateRelated(field,field.RelatedBuildID)" -->
                        <v-select                                                   
                            v-model="NewTableOption.LookupBuilds"
                            :items="LookupEntitiesSubCols"
                            class="mx-2"
                            item-text="id"
                            label="Lookup Builds"
                            multiple
                            return-object
                             @change="ResetTableOptionDialog()"
                            ></v-select>
                            
                    </v-list-item>
                    <v-list  v-if="NewTableOption.Type === 'Lookup'">
                        <v-list-item class="black">
                            <v-list-item-content class="white--text">
                                Related DataSet
                            </v-list-item-content>
                            <v-list-item-content class="white--text" v-for="header in NewTableOption.Headers" :key="header.itemObjKey">
                                {{header.Name}}
                            </v-list-item-content>
                            </v-list-item>
                        <v-list-item v-for="build in NewTableOption.LookupBuilds" :key="build.itemObjKey">
                            <v-list-item-content>
                                {{build.tempname}}
                            </v-list-item-content>                                                        
                            <v-list-item-content v-for="(header,headerindex) in NewTableOption.Headers" :key="header.itemObjKey">
                                    <v-autocomplete @change="CheckForPrimary(NewTableOption,build.LinkedFields[header.value],header,headerindex)" dense :label="header.text" v-model="build.LinkedFields[header.value]" :items="build.AllFields" item-text="DisplayName" return-object></v-autocomplete>
                            </v-list-item-content>
                        </v-list-item>                                                
                    </v-list>
                    
                    <v-list-item v-if="NewTableOption.Type === 'Lookup'">
                        <v-switch v-model="NewTableOption.Lookup_Once_Only" label="Records can link Once Only" />
                    </v-list-item>
                    <v-list-item>
                        <v-switch v-model="NewTableOption.ConfigValueasDocID" label="ConfigValueasDocID" />
                    </v-list-item>
                    <v-list-item v-if="NewTableOption.ConfigValueasDocID">
                        <v-select                                                   
                            v-model="NewTableOption.DocIDProp"
                            :items="NewTableOption.Headers"
                            class="mx-2"
                            item-text="Name"
                            label="DocIDProp"
                            
                            ></v-select>
                    </v-list-item>
                    
                </v-list>
            </v-card-text>
            <v-card-text>
                <!-- <v-btn @click="ResetTableOptionDialog()">Refresh</v-btn> -->
                <TableEmbedTab 	v-if="SampleTableOption.OptionType === 'SubCollection' && SampleTableOption.Entityid"	
                :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="''" :rowindex="rowindex"		
                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"					
                :tab="SampleTableOption" @OpenDialog="OpenDialog" :ParentTab="''" :DynamicWikiData="SampleRecord"									
                />
                <!-- <DataSingleDocumentRegister :EntityName="EntityName" :BaseStorageRef="BaseStorageRef"
                :Record="Record" 	v-if="SampleTableOption.OptionType === 'Document Register' && SampleTableOption.Entityid"	
                :SystemEntities="SystemEntities" :SingleFormEditing="SingleFormEditing" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"
                @OpenDialog="OpenDialog" :sectionindex="sectionindex" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
                :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj"
                :AppointmentsQuery="AppointmentsQuery" :ActivitiesArray="ActivitiesArray" :CanGet="true"
                :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="true" :CanCreate="true" :CanDelete="true"
                />
                <DataSingleImageGallery  :EntityName="EntityName" :EntityCollectionRef="EntityCollectionRef"  :BaseStorageRef="BaseStorageRef" :EntityDataRef="EntityDataRef"
                @UpdateEditableField="UpdateEditableField"
                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj"
                @OpenDialog="OpenDialog" :sectionindex="sectionindex" :tabindex="tabindex"
                :PrimaryFieldName="PrimaryFieldName" :SingleFormEditing="SingleFormEditing" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
                :Record="Record" v-if="SampleTableOption.OptionType === 'Gallery'" :AdditionalSaveMethod="AdditionalSaveMethod"
                :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                /> -->
                <!--TablePreview Config 
                    <SingleTabFieldsSection
                    @UpdateEditableField="UpdateEditableField"
                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj"
                :sectionindex="''" :tabindex="''" :SampleOnly="true"
                :PrimaryFieldName="''" :SingleFormEditing="false"
                :Record="SampleRecord" :AdditionalSaveMethod="AdditionalSaveMethod"
                :section="''" :tab="''" :Fields="[field]" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                /> -->
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelTableOptionDialog()">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="AddTableOption(NewTableOption)">
                    {{NewTableOption.id? 'Update '+NewTableOption.id : 'Add '+TableOptionsName}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-list  >
         <v-btn outlined @click="ActivateTableOptionDialog(DefaultTableOption,true)">
        <v-icon>mdi-plus</v-icon>
        Add
        </v-btn>
        <v-list-item @click="ConfigureTable(subcol)" class="detailslistoutline" v-for="subcol in RelatedSubcols" :key="subcol.itemObjKey">
            {{subcol.Name}}
        </v-list-item>
    </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import TableEmbedTab from '@/components/WebPages/RenderComponents/TableEmbedTab';
import FieldConfig from '@/components/Database/Fields/FieldConfig';	
import SingleTabFieldsSection from '@/components/Database/SingleTabFieldsSection';
import OptionSetDialogComponent from '@/components/SuiteBuilder/SuiteApps/Settings/DBBuilder/OptionSetDialog';	
import CabinetORSubcolConfig from '@/components/Database/CabinetORSubcolConfig';
export default {
    props: ['System','SystemEntities','RelatedObj','CurrentEntity','EntityCollectionRef','SampleRecord','DataViewName','RADB'],
    components: {TableEmbedTab,FieldConfig,SingleTabFieldsSection,OptionSetDialogComponent,CabinetORSubcolConfig},
    data() {
        return {
            OptionSetDialog: false,
            EditOptionSet: '',	
            RefreshingNewField: false,
            NewField: '',
            AddingNewField: false,
            NewTableOption: '',
            SampleTableOption: '',
            NewTableOptionDialog: false,
            NewFieldDialog: false,
            SampleRecord: {},
            ExistingFieldSearch: '',
            ExistingFormFieldSearch: '',
            NewFieldSearch: '',
        }
    },	
    computed:{
        RelatedSubcols(){
            return this.DefaultTableOption && this.CurrentEntity[this.DefaultTableOption.ConfigCollection] ? 
            this.CurrentEntity[this.DefaultTableOption.ConfigCollection] : []
        },
        RAConfigDB(){
            return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id)
        },
        NeedsRASync(){
            return this.$store.state.NeedsRASync
        },
        TableOptionsName(){
            if(this.DataViewName === 'Data Single Related'){
                return 'Tables'
            }
            else if(this.DataViewName === 'Data Single Cabinet'){
                return ' Cabinets'
            }
            else if(this.DataViewName === 'Data Single Document Register'){
                return ' Document Register'
            }
            else if(this.DataViewName === 'Data Single Gallery'){
                return ' Gallery'
            }
            else if(this.DataViewName === 'Data Single SubCollection'){
                return ' SubCollection'
            }
        },
        DefaultTableOption(){
            if(this.DataViewName === 'Data Single SubCollection'){
                return {
                        Name: '',
                        DisplayName: '',
                        OptionType: 'SubCollection',
                        ConfigCollection: 'SubCollections',
                        Headers: [],
                        HeadersInteger: 1000000
                    }
            }
            else if(this.DataViewName === 'Data Single Document Register'){
                return {
                        Name: '',
                        DisplayName: '',
                        OptionType: 'Document Register',
                        ConfigCollection: 'DocumentRegisters'
                    }
            }
            else if(this.DataViewName === 'Data Single Gallery'){
                return {
                        Name: '',
                        DisplayName: '',
                        OptionType: 'Gallery',
                        ConfigCollection: 'Galleries'
                    }
            }
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        ConfigureTable(tab){
            console.log(tab)
            if(tab.MultipleFileUploadType === 'SubCollection'){
                let opt = this.CurrentEntity.SubCollections.find(obj => obj.id === tab.id)
                this.ActivateTableOptionDialog(opt)
            }
            else if(tab.MultipleFileUploadType === 'Document Register'){
                let opt = this.CurrentEntity.DocumentRegisters.find(obj => obj.id === tab.id)
                this.ActivateTableOptionDialog(opt)
            }
            else if(tab.MultipleFileUploadType === 'Gallery'){
                let opt = this.CurrentEntity.Galleries.find(obj => obj.id === tab.id)
                this.ActivateTableOptionDialog(opt)
            }
            else{
                let opt = this.CurrentEntity[tab.ConfigCollection].find(obj => obj.id === tab.id)
                this.ActivateTableOptionDialog(opt)
            }
        },
        ActivateTableOptionDialog(tableoption){
            this.NewTableOption = Object.assign({},tableoption)
            if(this.NewTableOption.ConfigCollection === 'SubCollections' && this.NewTableOption.Type === 'Lookup' && this.NewTableOption.LookupBuilds){
                  this.NewTableOption.LookupBuilds = this.NewTableOption.LookupBuilds.map(build => {
                  let lookupbuild = this.SystemEntities.find(obj => obj.id === build.id)
                  console.log(build.id,this.NewTableOption,this.NewTableOption.LookupBuilds,lookupbuild)
                  if(lookupbuild){
                    build.AllFields = lookupbuild.AllFields
                    build.Entity_Type = lookupbuild.Entity_Type
                  }
                  return build
                  })
            }
             //.DocRegAdditionalFields.map?
            this.SampleTableOption = Object.assign({},tableoption)
            this.SampleTableOption.BGColor = {									
                    hex: '#ffffff',
                    hexa: '#ffffffff',									
            }
            console.log(this.SampleTableOption)
            this.NewTableOptionDialog = true
        },
        ResetTableOptionDialog(){
            this.NewTableOptionDialog = false
            
            this.SampleTableOption.Entityid = this.NewTableOption.Name.split(' ').join('_')
            this.SampleRecord.SubCollections = []
            this.SampleRecord.SubCollections.push(this.NewTableOption)
            if(this.SampleTableOption.OptionType === 'SubCollection'){
                this.SampleTableOption.EntitySource = 'SubCollection'
            }
            console.log(this.NewTableOption,this.SampleTableOption)
            setTimeout(() => {
                    this.NewTableOptionDialog = true
            }, 10);
        },
        AddTableOption(opt){
            // console.log(this.EntityCollectionRef,opt.ConfigCollection,opt)
            // console.log(this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.Name))
            // console.log('CheckObjectforUndefined')
            opt.Name = opt.DisplayName.split(' ').join('_')
            this.CheckObjectforUndefined(1,opt,'('+opt.Name+')')
            if(!this.NeedsRASync){
               this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.Name).set(opt).then(newdoc => {
                    this.CancelTableOptionDialog()
                })
            }
            else{
                this.RAConfigDB.collection(opt.ConfigCollection).doc(opt.id).set(opt).then(radoc => {
                    this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.id).set(opt).then(newdoc => {
                        this.CancelTableOptionDialog()
                    })
                })
            }
            
        },
        CheckObjectforUndefined(lvl,obj,path){
      for(var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          let newpath = path+'/'+prop
          if(typeof obj[prop] === 'undefined'){
            console.log('LVL '+lvl+' prop UNDEFINED!!! '+newpath,obj)
          }
          else{
            if(!Array.isArray(obj[prop])){
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',obj[prop])
              //repeat this function somehow
              if(typeof obj[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,obj[prop],newpath)
              }
              
            }
            else{              
              //call array undefined check
              this.CheckArrayforUndefined(lvl-1+2,obj[prop],newpath)
            }
          }
        }
      }
    },
    CheckArrayforUndefined(lvl,obj,path){
      //console.log('checkingprop '+checkingprop+' on path '+path)
      obj.map(arrayrecord => {
        for(var prop in arrayrecord) {
          if (arrayrecord.hasOwnProperty(prop)) {
            let newpath = path+'/'+prop
            if(typeof arrayrecord[prop] === 'undefined'){
              console.log('LVL '+lvl+' array prop UNDEFINED!!! '+newpath,arrayrecord)
            }
            else{
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',arrayrecord[prop])
              if(!Array.isArray(arrayrecord[prop])){
                if(typeof arrayrecord[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                }
              }
              else{
                this.CheckArrayforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                //prop is array check for third prop
                // arrayrecord[prop].map(thirdrecord => {
                // })
              }
            }
          }
        }
      })
    },
        CancelTableOptionDialog(){
            this.NewTableOptionDialog = false,
            this.NewTableOption = ''
            this.SampleTableOption = ''
            console.log(this.NewTableOption)
            //this.NewTableOption = ''
        },
        SaveOptionSets(fieldconfig){	
            if(!fieldconfig){
            let fieldobj = this.EditedTab.FormFields.find(obj => obj.Name === this.EditOptionSet.Name)									
            let fieldindex = this.EditedTab.FormFields.indexOf(fieldobj)									
            this.EditedTab.FormFields[fieldindex].Options = this.EditOptionSet.Options									
            this.OptionSetDialog = false									
            this.NewContentDialog = false									
            this.NewContentDialog = true
            }	
            else{
                this.OptionSetDialog = false
            }							
                                                
        },	
        CancelOptionSetDialog(){									
            this.OptionSetDialog = false
            this.EditOptionSet = ''		
            //console.log(this.EditOptionSet,this.OptionSetDialog)			
        },		
        ActivateOptionSetDialog(field,ContentBlockObject){									
            this.EditOptionSet = field		
            if(field.PrimaryOptions){
                this.EditOptionSet.Options = field.PrimaryOptions
                this.EditOptionSet.lastassignedinteger = field.PrimaryOptionsInteger
            }							
            if(!this.EditOptionSet.Options){									
            this.EditOptionSet.Options = []									
            }									
            this.OptionSetDialog = true
           // console.log(this.EditOptionSet,this.OptionSetDialog)									
        },		
        CancelNewFieldDialog(){
            this.NewFieldDialog = false,
            this.NewField = ''
             this.AddingNewField = false
             this.ListPanel = ''
            //this.NewTableOption = ''
        },
        AddField(field){
            let fieldobj = Object.assign({},field)
            fieldobj.Name = fieldobj.DisplayName.split(' ').join('_')
            fieldobj.id = fieldobj.Name
            delete fieldobj.Array
            delete fieldobj.RecordData
            
            if(this.NewTableOption.OptionType === 'SubCollection'){
                this.NewTableOption.Headers.push(field)
                this.NewTableOption.HeadersInteger = this.NewTableOption.HeadersInteger-1+2
                if(!this.ImportFileSessionName){
                    setTimeout(() => {
                        this.CancelNewFieldDialog()
                    }, 50);   
                }
                
            }
            
            
        },
        ActivateNewFieldDialog(field,AddingNewField,ListPanel){
            //console.log('field',field)
            this.NewField = Object.assign({},field)
            this.NewFieldDialog = true
            this.AddingNewField = AddingNewField
            this.ListPanel = ListPanel
            console.log(this.EntityCollectionRef,field)
            // if(tableoption){
            //     this.NewTableOption = tableoption 
            // }
        },
        NoPropsemit(MethodName){
            this[MethodName]()
        },
        Onepropemit(MethodName,prop){
            //console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>



